import { useEffect } from "react";
import useSocket from "@/common/hook/useSocket";
import { useDispatch, useSelector } from "react-redux";
import { addToastNotification } from "@/store/commonSlice";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import request from "@/utils/request";
import ToastNotificationModel from "@/common/models/ToastNotificationModel";
import ToastNotificationItem from "./ToastNotificationItem";

const ToastNotification = () => {
  const notifications = useSelector((state) => state.common.toastNotifications);
  const dispatch = useDispatch();
  const socket = useSocket();

  useEffect(() => {
    if (!AuthTokenStore.has()) {
      const timeout = setTimeout(() => {
        if (AuthTokenStore.has()) return;
        request.get("/notify").then(({ data }) => {
          data.forEach((notification) => {
            dispatch(addToastNotification(new ToastNotificationModel(notification.message.message)));
          });
        });
      }, 20 * 1000);

      return () => clearTimeout(timeout);
    }

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    socket.on("toast", (toast) => dispatch(addToastNotification({ id: Date.now(), ...toast })));
    return () => socket.off("toast");
  }, [dispatch, socket]);

  return (
    <div className="pointer-events-none fixed bottom-0 left-0 z-40 max-h-screen w-full overflow-hidden py-8 px-4 md:z-50 md:max-w-md">
      <div className="pointer-events-none flex flex-col space-y-4">
        {notifications.map((notification) => (
          <ToastNotificationItem key={notification.id} notification={notification} />
        ))}
      </div>
    </div>
  );
};

export default ToastNotification;
