export default function ChatMessage(message, from, to, isMine, type = null) {
  const createdAt = Date.now();

  return {
    id: createdAt + Math.random(),
    messageFromId: from,
    messageToId: to,
    type,
    message,
    isReaded: false,
    createdAt,
    me: isMine,
  };
}
