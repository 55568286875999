import { useEffect } from "react";
import useSocket from "@/common/hook/useSocket";
import { useDispatch } from "react-redux";
import {
  setInquiryFrom,
  deleteInquiryFrom,
  deleteInquiryTo,
  addChatConversation,
  addChatMessage,
  setScrollToBottom,
} from "@/store/chatSlice";
import ChatConversationListItem from "@/common/models/ChatConversationListItem";
import ChatMessage from "@/common/models/ChatMessage";

const SocketOn = () => {
  const socket = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    // Socket: chat.inquiry
    socket.on("chat.inquiry", (data) => {
      dispatch(addChatConversation(new ChatConversationListItem(data)));
      dispatch(setInquiryFrom(data.senderId));
      dispatch(setScrollToBottom(true));
      dispatch(setScrollToBottom(false));
    });

    socket.on("chat.inquiry.abort", (data) => {
      dispatch(
        addChatMessage(ChatMessage("Die Einladung wurde zurückgezogen.", data.receiverId, data.senderId, false, "info"))
      );
      dispatch(deleteInquiryFrom(data.senderId));
      dispatch(setScrollToBottom(true));
      dispatch(setScrollToBottom(false));
    });

    socket.on("chat.inquiryFrom.abort", (data) => {
      dispatch(
        addChatMessage(ChatMessage("Deine Einladung wurde abgelehnt.", data.receiverId, data.senderId, false, "info"))
      );
      dispatch(deleteInquiryTo(data.senderId));
      dispatch(setScrollToBottom(true));
      dispatch(setScrollToBottom(false));
    });

    socket.on("chat.inquiryFrom.accept", (data) => {
      dispatch(deleteInquiryTo(data.senderId));
      dispatch(setScrollToBottom(true));
      dispatch(setScrollToBottom(false));
    });
  }, [socket, dispatch]);
  return null;
};

export default SocketOn;
