import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  isFilterSectionOpen: false,
  category: null,
  categoryGroup: null,
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    toggleSearchFilterSection: (state) => {
      state.isFilterSectionOpen = !state.isFilterSectionOpen;
    },
    setSearchCategorySlug: (state, action) => {
      const categorySlug = action.payload;

      if (categorySlug) {
        // eslint-disable-next-line no-restricted-syntax
        for (const category of state.categories) {
          if (category.main.slug === categorySlug) {
            state.category = category.main;
            state.categoryGroup = category;
            break;
          } else {
            const findInSub = category.sub.find((sub) => sub.slug === categorySlug);
            if (findInSub) {
              state.category = findInSub;
              state.categoryGroup = category;
              break;
            }
          }
        }
      } else {
        state.category = null;
        state.categoryGroup = null;
      }
    },
    setSearchCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { toggleSearchFilterSection, setSearchCategorySlug, setSearchCategories } = searchSlice.actions;

export default searchSlice.reducer;
