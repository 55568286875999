import { mutate } from "swr";
import request from "@/utils/request";
import BaseStore from "./BaseStore";

export default class AuthTokenStore extends BaseStore {
  static KEY = "token";

  static set(value, ctx = null) {
    // for browsers
    if (ctx === null) {
      request.defaults.headers.common.Authorization = `Bearer ${value}`;
      mutate("/consultants/categories").then();
    }

    super.set(value, ctx);
  }

  static delete(ctx = null) {
    if (ctx === null && request.defaults.headers.common.Authorization) {
      delete request.defaults.headers.common.Authorization;
      mutate("/consultants/categories").then();
    }

    super.delete(ctx);
  }

  static initialize() {
    const token = this.get();
    if (token) {
      request.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  }
}
