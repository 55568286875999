import React from "react";
import { persistor } from "@/store";
import { PersistGate } from "redux-persist/integration/react";
import isBrowser from "@/utils/isBrowser";

const PersistGateLayout = ({ children }) => {
  if (isBrowser()) {
    return (
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    );
  }

  return children;
};

export default PersistGateLayout;
