export default function ChatConversationListItem(id, name, image, lastMessage) {
  const badTimeFormat = { date: Date.now(), timezone_type: 3, timezone: "Europe/Berlin" };

  return {
    id,
    image,
    name,
    state: 1,
    tariff: null,
    lastMessage,
    lastOnline: badTimeFormat,
    lastMessageTime: badTimeFormat,
  };
}
