import ReactHowler from "react-howler";
import NotificationItem from "@/components/common/NotificationItem";
import Sounds from "@/common/models/Sounds";
import { useDispatch } from "react-redux";
import { dismissToastNotification } from "@/store/commonSlice";
import { memo } from "react";
import { XIcon } from "@heroicons/react/outline";

const ToastNotificationItem = ({ notification }) => {
  const dispatch = useDispatch();
  const dismiss = () => dispatch(dismissToastNotification(notification.id));

  return (
    <div className="pointer-events-auto flex w-full animate-fade-in rounded-2xl bg-white p-4 shadow-xl transition-all duration-300">
      <NotificationItem notification={notification.message} type={notification.type} handleClick={dismiss} />
      <button
        type="button"
        className="flex-center ml-4 h-6 w-6 shrink-0 cursor-pointer rounded-full bg-grey-400 opacity-50 hover:opacity-100"
        onClick={dismiss}
      >
        <XIcon className="h-3 w-3 text-grey-800" />
      </button>
      {Sounds[notification.event] && <ReactHowler src={Sounds[notification.event]} />}
    </div>
  );
};

export default memo(ToastNotificationItem);
