import useTranslation from "next-translate/useTranslation";
import { useCallback, useMemo } from "react";
import Router from "next/router";
import Avatar from "@/components/common/Avatar";
import friendlySeconds from "@/utils/friendlySeconds";
import { useDispatch } from "react-redux";
import { setChatOpen, setChatReceiverId } from "@/store/chatSlice";

const NotificationItem = ({
  id,
  notification,
  type,
  createdAt = Date.now(),
  handleClick,
  chatAvatarClassName = "h-9 w-9",
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const friendlyTime = useMemo(() => {
    const diff = Math.floor((Date.now() - new Date(createdAt).getTime()) / 1000);
    return t("common:format.time-ago", { time: friendlySeconds(diff, t) });
  }, [createdAt, t]);
  const openChat = useCallback(() => {
    dispatch(setChatReceiverId(notification.userId));
    dispatch(setChatOpen(true));
  }, [dispatch, notification.userId]);

  const onClick = () => {
    if (handleClick) handleClick();

    switch (type) {
      case "feature":
        Router.push(`/news#${id}`);
        break;
      case "chat":
        openChat();
        break;
      case "info":
        if (notification.link !== null) {
          window.location.assign(notification.link);
        }
        break;
      default:
    }
  };

  return (
    <div
      className="flex min-w-0 grow cursor-pointer select-none items-center justify-start"
      onClick={onClick}
      role="presentation"
    >
      <Avatar
        className={`${chatAvatarClassName} flex-shrink-0`}
        image={notification.image}
        author={notification.name || notification.title}
      />
      <div className="ml-3 flex min-w-0 grow flex-col">
        <div className="flex items-center justify-between">
          <span className="w-full grow truncate text-xs font-semibold text-grey">{notification.title}</span>
          <span className="shrink-0 text-xs text-blue">{friendlyTime}</span>
        </div>
        <p className="mt-0.5 break-words text-xs text-grey-800">{notification.message}</p>
      </div>
    </div>
  );
};

export default NotificationItem;
