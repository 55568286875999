import Image from "next/image";

const Avatar = ({ image, author, className }) =>
  typeof image === "string" ? (
    <div className={`relative overflow-hidden rounded-full ${className}`}>
      <Image src={image} layout="fill" objectFit="cover" objectPosition="top" alt={author} />
    </div>
  ) : (
    <div className={`flex-center rounded-full bg-blue text-white ${className}`} title={author}>
      {author ? author.substring(0, 2).toUpperCase() : "?"}
    </div>
  );

export default Avatar;
