import { createSelector, createSlice } from "@reduxjs/toolkit";
import request from "@/utils/request";
import { mutate } from "swr";

const MESSAGES_PER_PAGE = 30;

const initialState = {
  open: false,
  isMobile: true,
  currentReceiverId: null,
  conversations: [],
  hasNotBalance: false,
  hasSession: false,
  connectedAt: null,
  messages: [],
  isMessagesLoading: false,
  isStarted: false,
  needSession: true,
  inquiryTo: [],
  inquiryFrom: [],
  inquiryType: "chat",
  welcomeText: null,
  scrollToBottom: false,
  chatCount: null,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatNeedSession: (state, action) => {
      state.needSession = action.payload;
    },
    setChatOpen: (state, action) => {
      state.open = action.payload;
      request.get(`/chat/count/flush`).then((r) => r.data);
      mutate("/chat/count/unread");

      if (action.payload === false) {
        request.get("/connection/stop").then();
      }
    },
    setChatReceiverId: (state, action) => {
      state.currentReceiverId = action.payload;
      state.messages = [];
    },
    setRequestFrom: (state, action) => {
      state.requestFrom = action.payload;
    },
    setRequestTo: (state, action) => {
      state.requestTo = action.payload;
    },
    setRequestType: (state, action) => {
      state.requestType = action.payload;
    },
    setRequestStatus: (state, action) => {
      state.requestStatus = action.payload;
    },
    setChatConversations: (state, action) => {
      state.conversations = action.payload;
      if (state.currentReceiverId === null && action.payload.length) {
        state.currentReceiverId = action.payload[0].id;
      }
    },
    setChatNoBalance: (state, action) => {
      state.hasNotBalance = action.payload;
    },
    setInquiryTo: (state, action) => {
      if (state.inquiryTo.indexOf(action.payload) === -1) state.inquiryTo.push(action.payload);
    },
    setInquiryFrom: (state, action) => {
      if (state.inquiryFrom.indexOf(action.payload) === -1) state.inquiryFrom.push(action.payload);
    },
    setInquiryType: (state, action) => {
      state.inquiryType = action.payload;
    },
    setScrollToBottom: (state, action) => {
      state.scrollToBottom = action.payload;
    },
    deleteInquiryTo: (state, action) => {
      const index = state.inquiryTo.indexOf(action.payload);
      if (index > -1) state.inquiryTo.splice(index, 1);
    },
    deleteInquiryFrom: (state, action) => {
      const index = state.inquiryFrom.indexOf(action.payload);
      if (index > -1) state.inquiryFrom.splice(index, 1);
    },
    closeChatSession: (state) => {
      state.hasSession = false;
      state.connectedAt = null;
      state.isStarted = false;
    },
    handleChatCheckSuccess: (state, action) => {
      const data = action.payload;
      state.connectedAt = data.connectedAt ?? null;
      state.hasSession = data.success;
      state.isStarted = data.status === "connected";

      if (data.isConsultant) {
        state.hasNotBalance = false;
      }
    },
    updateChatConversation: (state, action) => {
      const { conversationId, key, value } = action.payload;

      const index = state.conversations.findIndex((c) => c.id === conversationId);
      if (index !== -1) {
        state.conversations[index][key] = value;
      }
    },
    addChatConversation: (state, action) => {
      state.conversations.push(action.payload);
    },
    setChatIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setChatMessagesLoading: (state, action) => {
      state.isMessagesLoading = action.payload;
    },
    addChatMessages: (state, action) => {
      state.messages[action.payload.page] = action.payload.data;
      state.isMessagesLoading = false;
    },
    addChatMessage: (state, action) => {
      if (state.messages.length > 0) {
        state.messages[0].messages.push(action.payload);
      }
    },
    setChatCount: (state, action) => {
      state.chatCount = action.payload;
    },
  },
});

export const {
  setChatOpen,
  setChatReceiverId,
  handleChatCheckSuccess,
  setChatNoBalance,
  updateChatConversation,
  addChatConversation,
  setChatIsMobile,
  addChatMessage,
  setInquiryTo,
  setInquiryFrom,
  deleteInquiryFrom,
  deleteInquiryTo,
  setInquiryType,
  setScrollToBottom,
  setChatMessagesLoading,
  setChatCount,
} = chatSlice.actions;

export const closeChatSession = () => async (dispatch) => {
  dispatch(chatSlice.actions.closeChatSession());

  await mutate("/me", (u) => ({ ...u }));
};

export const fetchChatConversations = (dispatch) => {
  request
    .get("/chats")
    .then(({ data }) => dispatch(chatSlice.actions.setChatConversations(data)))
    .catch();
};

export const fetchChatCheck = (dispatch, getState) => {
  const { currentReceiverId, needSession } = getState().chat;

  if (needSession) {
    request
      .post(`/chat-check/${currentReceiverId}`)
      .then(({ data }) => dispatch(handleChatCheckSuccess(data)))
      .catch((err) => {
        dispatch(closeChatSession());

        // 406 => has no balance error code
        dispatch(setChatNoBalance(err.response?.status === 406));
      });
  }
};

export const fetchChatMessages =
  (loadMore = false) =>
  (dispatch, getState) => {
    const { currentReceiverId, messages, isMessagesLoading } = getState().chat;

    if (
      isMessagesLoading ||
      !currentReceiverId ||
      (messages.length > 0 && messages[messages.length - 1] < MESSAGES_PER_PAGE)
    ) {
      return;
    }

    dispatch(chatSlice.actions.setChatMessagesLoading(true));

    const page = loadMore ? messages.length : 0;
    request
      .get(`/chat/${currentReceiverId}?page=${page + 1}`)
      .then(({ data }) => dispatch(chatSlice.actions.addChatMessages({ data, page })))
      .catch(() => dispatch(chatSlice.actions.setChatMessagesLoading(false)));

    request.get(`/chat/count/flush/${currentReceiverId}`).then((r) => r.data);
  };

export const selectChatCurrentReceiver = createSelector(
  (state) => state.chat.conversations,
  (state) => state.chat.currentReceiverId,
  (conversations, currentReceiverId) => conversations.find((conversation) => conversation.id === currentReceiverId)
);

export default chatSlice.reducer;
