const times = {
  year: 60 * 60 * 24 * 365,
  month: 60 * 60 * 24 * 30,
  day: 60 * 60 * 24,
  hour: 60 * 60,
  minute: 60,
};

export default function friendlySeconds(seconds, translation) {
  const timeKeys = Object.keys(times);
  for (let i = 0; i < timeKeys.length; i += 1) {
    const keyword = timeKeys[i];
    const time = times[keyword];
    if (seconds >= time) {
      if (seconds >= times.day) {
        const friendlyDiff = Math.floor(seconds / time);
        if (friendlyDiff > 3) {
          return translation(`common:format.few-time.${keyword}`);
        }
      }

      return translation(`common:format.time.${keyword}`, { count: Math.floor(seconds / time) });
    }
  }

  return translation(`common:format.time.second`, { count: seconds });
}
