import { createContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { mutate } from "swr";
import AuthTokenStore from "@/common/store/AuthTokenStore";
import useUser from "@/common/hook/user/useUser";
import request from "@/utils/request";
import UserState from "@/common/models/UserState";
import { useDispatch } from "react-redux";
import { addToastNotification } from "@/store/commonSlice";
import ToastNotificationModel from "@/common/models/ToastNotificationModel";

const SocketContext = createContext(undefined);
export default SocketContext;

export const createSocketInstance = () =>
  io(process.env.NEXT_PUBLIC_SOCKET_URL, { auth: { token: AuthTokenStore.get() } });

export const SocketProvider = ({ children, socket: initialSocket }) => {
  const { user, isAuthenticated, loading } = useUser();
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(initialSocket);

  useEffect(() => {
    if (loading || (!socket.connected && !socket.disconnected)) {
      return;
    }
    if (Boolean(socket.auth.token) !== isAuthenticated) {
      socket.disconnect();
      setSocket(createSocketInstance());
    }
  }, [socket, isAuthenticated, loading]);

  useEffect(() => {
    const stacks = new Map();
    let runningPresenceUpdate = false;
    const presenceUpdate = ({ id, state }) => {
      stacks.set(id, state);

      if (!runningPresenceUpdate) {
        runningPresenceUpdate = true;
        setTimeout(() => {
          mutate("/consultants/categories");
          stacks.forEach((newState, userId) => {
            if (UserState.ONLINE.equals(newState) && user?.follows?.includes(userId)) {
              request.get(`/consultants/${userId}`).then(({ data }) => {
                dispatch(
                  addToastNotification(
                    new ToastNotificationModel({
                      image: `${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${data.picture}`,
                      name: data.displayName,
                      link: `/${data.slug}`,
                      title: `${data.displayName} ist Online!`,
                      message: `${data.displayName} ist jetzt Online! Jetzt ansehen!`,
                    })
                  )
                );
              });
            }
            mutate(`/consultants/${userId}`, (prev) => ({ ...prev, state: newState }), false);
          });
          stacks.clear();
          runningPresenceUpdate = false;
        }, 5000);
      }
    };

    socket.on("presence-update", presenceUpdate);

    return () => socket.off("presence-update", presenceUpdate);
  }, [dispatch, socket, user?.follows]);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
