import BaseStore from "./BaseStore";

export default class UserTypeStore extends BaseStore {
  static KEY = "user-type";

  static CONSULTANT = "consultant";

  static CUSTOMER = "customer";

  static valid(value, isConsultant, isCustomer) {
    if (value === null) {
      return isConsultant ? this.CONSULTANT : this.CUSTOMER;
    }

    if (value === this.CONSULTANT && !isConsultant) {
      return this.CUSTOMER;
    }

    if (value === this.CUSTOMER && !isCustomer) {
      return this.CONSULTANT;
    }

    return value;
  }
}
