import React, { useEffect } from "react";
import ReactHowler from "react-howler";
import Sounds from "@/common/models/Sounds";
import { useDispatch, useSelector } from "react-redux";
import { setPlayNotificationSound } from "@/store/commonSlice";

const PlaySound = () => {
  const dispatch = useDispatch();
  const [play, setPlay] = React.useState(false);
  const playNotificationSound = useSelector((state) => state.common.playNotificationSound);

  useEffect(() => {
    if (playNotificationSound) {
      setPlay(true);

      setTimeout(() => {
        setPlay(false);
        dispatch(setPlayNotificationSound(false));
      }, 2000);

      console.log("play sound");
    }
  }, [playNotificationSound]);

  return <>{play && <ReactHowler src={Sounds.push} />}</>;
};

export default PlaySound;
