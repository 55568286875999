import { parseCookies, setCookie, destroyCookie } from "nookies";
import { deserialize, serialize } from "@/utils/cookieSerializer";

export default class BaseStore {
  static KEY;

  static set(value, ctx = null, maxAge = 30 * 24 * 60 * 60) {
    setCookie(ctx, this.KEY, serialize(value), {
      maxAge,
      path: "/",
      sameSite: true,
    });
  }

  static delete(ctx = null) {
    destroyCookie(ctx, this.KEY);
  }

  static get(ctx = null) {
    const cookies = parseCookies(ctx);
    const item = cookies[this.KEY];
    return item ? deserialize(item) : null;
  }

  static has(ctx = null) {
    const cookies = parseCookies(ctx);
    return !!cookies[this.KEY];
  }
}
