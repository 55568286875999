/*
  type: "info",
  message: {
    image: `${process.env.NEXT_PUBLIC_STORAGE_URL}/consultants/${data.picture}`,
    name: data.displayName,
    link: `/${data.slug}`,
    title: `${data.displayName} ist Online!`,
    message: `${data.displayName} ist jetzt Online! Jetzt ansehen!`,
  },
  event: "push",
 */

export default function ToastNotificationModel(message, type = "info", event = "push") {
  return {
    id: Date.now(),
    type,
    message,
    event,
  };
}
